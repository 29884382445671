var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "invoice-preview-wrapper"
  }, [_c('b-row', {
    staticClass: "invoice-preview"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "9",
      "md": "8"
    }
  }, [_c('b-card', {
    staticClass: "invoice-preview-card",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "mt-md-0 mt-2"
  }, [_c('h4', {
    staticClass: "invoice-title"
  }, [_vm._v(" Invoice "), _c('span', {
    staticClass: "invoice-number"
  }, [_vm._v(": " + _vm._s(_vm.transaksiId.merchant_ref))])]), _c('div', {
    staticClass: "invoice-date-wrapper"
  }, [_c('p', {
    staticClass: "invoice-date-title"
  }, [_vm._v("Status")]), _c('p', {
    staticClass: "invoice-date"
  }, [_vm._v(" : "), _vm.transaksiId.status == 'PAID' ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(_vm._s(_vm.transaksiId.status))]) : _vm.transaksiId.status == 'UNPAID' ? _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(_vm._s(_vm.transaksiId.status))]) : _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.transaksiId.status))])], 1)]), _c('div', {
    staticClass: "invoice-date-wrapper"
  }, [_c('p', {
    staticClass: "invoice-date-title"
  }, [_vm._v("Tanggal")]), _c('p', {
    staticClass: "invoice-date"
  }, [_vm._v(": " + _vm._s(_vm.humanDate(_vm.transaksiId.created_at)))])])])]), _c('hr', {
    staticClass: "invoice-spacing"
  }), _c('b-card-body', {
    staticClass: "invoice-padding pt-0"
  }, [_c('b-row', {
    staticClass: "invoice-spacing"
  }, [_c('b-col', {
    staticClass: "p-0",
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('div', [_c('h6', {
    staticClass: "mb-2"
  }, [_vm._v("Konsumen :")]), _c('table', [_c('tbody', [_c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v(_vm._s(_vm.transaksiId.customer_name))])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v(" " + _vm._s(_vm.transaksiId.customer_phone) + " ")])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_c('p', [_vm._v(_vm._s(_vm.transaksiId.customer_email))])])])])])])]), _c('b-col', {
    staticClass: "p-0 mt-xl-0 d-flex justify-content-xl-end",
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('div', [_c('h6', {
    staticClass: "mb-2"
  }, [_vm._v("Rincian Pembayaran :")]), _c('table', [_c('tbody', [_c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Metode")]), _c('td', [_vm._v(": " + _vm._s(_vm.transaksiId.payment_name))])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Tipe")]), _c('td', [_vm._v(" : " + _vm._s(_vm.transaksiId.payment_type == "payment_gateway" ? "Payment Gateway" : " Manual") + " ")])])])])])])], 1)], 1), _c('b-table-lite', {
    attrs: {
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.transaksiData
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(price)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(data.value)) + " ")];
      }
    }, {
      key: "cell(subtotal)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(data.item.price * data.item.quantity)) + " ")];
      }
    }, {
      key: "cell()",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.value) + " ")];
      }
    }])
  }), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-md-0 mt-3",
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "2",
      "order-md": "1"
    }
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Note:")]), _c('span', {
    staticClass: "ml-75"
  }, [_vm._v(_vm._s(_vm.transaksiId.note != null ? _vm.transaksiId.note : "-"))])])], 1), _c('b-col', {
    staticClass: "mt-md-6 d-flex justify-content-end",
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "1",
      "order-md": "2"
    }
  }, [_c('div', {
    staticClass: "invoice-total-wrapper"
  }, [_c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v("Subtotal:")]), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" Rp." + _vm._s(_vm.formatRupiah(_vm.transaksiId.amount_received)) + " ")])]), _c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v("Discount:")]), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" Rp." + _vm._s(_vm.transaksiId.discount != null ? _vm.formatRupiah(_vm.transaksiId.discount) : 0) + " ")])]), _c('hr', {
    staticClass: "my-50"
  }), _c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v("Total:")]), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" Rp." + _vm._s(_vm.formatRupiah(_vm.transaksiId.amount_received)) + " ")])])])])], 1)], 1), _c('hr', {
    staticClass: "invoice-spacing"
  }), _c('b-card-body', {
    staticClass: "invoice-padding pt-0"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Instruksi: ")])])], 1)], 1), _c('b-col', {
    staticClass: "invoice-actions",
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "3"
    }
  }, [_c('b-card', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "outline-primary",
      "block": "",
      "to": {
        name: 'admin-transaksi-ebook'
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-20",
    attrs: {
      "icon": "ArrowLeftIcon"
    }
  }), _vm._v(" Kembali ")], 1), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "outline-info",
      "block": ""
    },
    on: {
      "click": _vm.printInvoice
    }
  }, [_c('feather-icon', {
    staticClass: "mr-20",
    attrs: {
      "icon": "PrinterIcon"
    }
  }), _vm._v(" Print ")], 1), _vm.transaksiId.payment_type == 'manual' && _vm.transaksiId.status == 'UNPAID' ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-success",
      "block": ""
    },
    on: {
      "click": _vm.confirmPayment
    }
  }, [_c('feather-icon', {
    staticClass: "mr-20",
    attrs: {
      "icon": "CheckSquareIcon"
    }
  }), _vm._v(" Konfirmasi Pembayaran ")], 1) : _vm._e(), _vm.transaksiId.payment_type == 'manual' && _vm.transaksiId.status == 'UNPAID' ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "outline-danger",
      "block": ""
    },
    on: {
      "click": _vm.HapusBuktiTF
    }
  }, [_c('feather-icon', {
    staticClass: "mr-20",
    attrs: {
      "icon": "TrashIcon"
    }
  }), _vm._v(" Hapus Bukti Transfer ")], 1) : _vm._e(), _vm.transaksiId.status == 'UNPAID' ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "outline-danger",
      "block": ""
    },
    on: {
      "click": _vm.CancelOrder
    }
  }, [_c('feather-icon', {
    staticClass: "mr-20",
    attrs: {
      "icon": "XCircleIcon"
    }
  }), _vm._v(" Batalkan Order ")], 1) : _vm._e()], 1), _vm.transaksiId.payment_type == 'manual' ? _c('b-card', [_c('b-card-text', [_c('b', [_vm._v("Bukti Transfer")]), _c('br'), _vm.transaksiIdOrder != null ? _c('small', [_vm._v("(klik pada gambar untuk lihat gambar lebih jelas)")]) : _vm._e(), _vm.transaksiIdOrder == null ? _c('small', [_vm._v("(belum tersedia bukti transfer)")]) : _vm._e()]), _c('div', {
    directives: [{
      name: "viewer",
      rawName: "v-viewer"
    }],
    staticClass: "images"
  }, [_c('b-img', {
    staticClass: "card-img mb-2",
    attrs: {
      "src": _vm.transaksiIdOrder != null ? 'http://file.elp-dev.my.id/' + _vm.transaksiIdOrder.file : require('@/assets/images/elp/no-image.jpg')
    }
  })], 1), _c('hr'), _c('b-card-text', [_c('div', [_c('h6', {
    staticClass: "mb-2"
  }, [_vm._v("Rekening :")]), _c('table', [_c('tbody', [_c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Kode")]), _c('td', [_vm._v(" : " + _vm._s(_vm.transaksiIdOrder != null ? _vm.transaksiIdOrder.rekening.code : "-") + " ")])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Nama Bank")]), _c('td', [_vm._v(" : " + _vm._s(_vm.transaksiIdOrder != null ? _vm.transaksiIdOrder.rekening.name : "-") + " ")])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Nomor Rek.")]), _c('td', [_vm._v(" : " + _vm._s(_vm.transaksiIdOrder != null ? _vm.transaksiIdOrder.rekening.number : "-") + " ")])])])])])])], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }